export class OrderSync {
  private form: HTMLFormElement | null = null;

  public init() {
    const form = document.getElementsByName('order')[0] as HTMLFormElement;

    if (form) {
      this.form = form;

      for (const element of this.form.elements) {
        element.addEventListener('input', this.storeValues);
      }

      this.restoreValues();
    }
  }

  private storeValues() {
    const values: any = {};

    if (!this.form) {
      return;
    }

    for (const element of (this.form.elements as any)) {
      const name = element.name;
      const value = element.value;

      if (name.startsWith('wines')) {
        values[name] = value;
      }
    }

    localStorage.setItem('order.items', JSON.stringify(values));
  }

  private restoreValues() {
    if ((window as any).formSuccess === true) {
      localStorage.setItem('order.items', JSON.stringify({}));
      return;
    }

    const values = JSON.parse(localStorage.getItem('order.items') || '{}');

    for (const name of Object.keys(values)) {
      const element: any = document.getElementsByName(name)[0];

      if (element && !element.value) {
        element.value = values[name];
      }
    }
  }
}

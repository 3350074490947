<template>
    <nav class="navbar navbar-light fixed-top" :class="{collapsed: !showMenu}">
        <div class="brand">
            <a href="/" v-html="siteName" />
        </div>

        <transition name="slide-down">
            <div v-show="showMenu" class="menu-overlay">
                <slot />
            </div>
        </transition>

        <div class="navbar-toggler-wrapper" @click="toggleMenu">
            <span class="helper-text">Menu</span>
            <app-burger-button :crossed="showMenu" :color="burgerColor" crossed-color="primary"/>
        </div>
    </nav>
</template>

<script>
    export default {
        data: () => ({
            showMenu: false,
            burgerColor: 'primary'
        }),
        props: {
            siteName: {
                type: String,
                required: true
            },
            items: {
                type: Array
            }
        },
        mounted() {
            document.addEventListener('keydown', e => {
                if (e.key === 'Escape' && this.showMenu) {
                    this.close();
                }
            });
            window.addEventListener('barba.beforeEnter', () => {
                this.close();
            });
        },
        methods: {
            close() {
                this.showMenu = false;
            },
            toggleMenu() {
                this.showMenu = !this.showMenu;
            }
        }
    };
</script>

<style lang="scss">
    .navbar {
        flex-wrap: nowrap;

        @include media-breakpoint-down(lg) {
            padding: $navbar-padding-x / 1.5;
        }

        @include media-breakpoint-down(xs) {
            padding: $navbar-padding-x / 2.5;
        }

        img {
            max-height: 2.5rem;
        }

        .brand {
            z-index: 2;

            a {
                font-weight: $font-weight-bold;
                color: $primary;
                transition: $transition-base;
                text-transform: lowercase;
            }
        }

        .helper-text {
            opacity: 0;
            transition: $transition-base;
        }

        .navbar-toggler-wrapper {
            cursor: pointer;
            z-index: 1;
            outline: none;
            display: flex;

            .helper-text {
                text-transform: lowercase;
                margin-right: $spacer;

                @include media-breakpoint-down(md) {
                    color: $white;
                }
            }
        }

        &.collapsed {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;

            .brand a {
                color: $white;
            }

            .helper-text {
                opacity: 1;
            }
        }
    }

    .menu-overlay {
        display: flex;
        position: fixed;
        background: $white;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        color: $primary;
        overscroll-behavior: none;
        touch-action: none; /* prevent scrolling */
        overflow: hidden;
        transition: all .5s;

        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        text-align: center;

        @include media-breakpoint-down(md) {
            justify-content: space-between;
            padding: 10px;

            @media screen and (max-width: 450px) {
                .give-me-some-space {
                    min-height: 100px;
                }
            }

            @media screen and (orientation: landscape) {
                .contact {
                    font-size: 1rem;
                }
            }
        }

        ul {
            display: flex;
            flex-direction: column;

            li {
                font-size: 4rem;
                transition: margin-left .2s;
                line-height: 1.2;

                .nav-link {
                    transition: $transition-base;
                    padding: 0 0.5rem;
                }

                &:hover {
                    margin-left: 20px;
                }

                @include media-breakpoint-down(lg) {
                    font-size: calc(3vh + 3vw + 5px);
                }

                @include media-breakpoint-down(md) {

                    @media screen and (orientation: landscape) {
                        font-size: 2rem;
                    }
                }

                @include media-breakpoint-down(xs) {
                    /*font-size: 2.5rem;*/
                    /*font-size: calc(6vh + 20px);*/
                    line-height: 1.2;
                }
            }
        }
    }

    body.is-animating .menu-overlay {
        background: #eee;
        filter: saturate(0);
        cursor: wait;

        > * {
            pointer-events: none;
        }
    }
</style>

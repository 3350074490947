import $ from 'jquery';
import {onLoad} from '@/scripts/onload';

export default {
  submitting: false,
  success: false,
  submission: null,
  errors: {},
  init() {
    this.errors = {};
    this.success = false;
    this.submitting = false;

    // @ts-ignore
    this.$nextTick(() => {
      onLoad();
    });
  },
  submit(e: Event) {
    e.preventDefault();

    const form = e.target as HTMLFormElement;
    const data = $(form).serialize();

    this.submitting = true;

    return $.ajax({
      url: form.getAttribute('action') || '',
      type: form.getAttribute('method') || '',
      dataType: 'json',
      data
    })
        .then(res => {
          this.success = true;
          (window as any).formSuccess = true;

          if (!Array.isArray(res.submission)) {
            this.submission = res.submission;
          }
        })
        .catch((err: any) => {
          this.errors = err.responseJSON.field_errors;
        })
        .always(() => {
          this.submitting = false;
        });
  }
};

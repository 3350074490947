import autosize from './autosize';
import fitFontSize from './fit-font-size';
import {OrderSync} from '@/scripts/onload/order-sync';

export const onLoad = () => {
  autosize();
  fitFontSize();

  const order = new OrderSync();
  order.init();
};
